import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        image={frontmatter.thumbnail}
        article={true}
      />
      <div className="blog-post-container">
        <article className="post">
          <div
            className="post-thumbnail"
            style={{
              backgroundImage: !!frontmatter.thumbnail
                ? `url(${frontmatter.thumbnail})`
                : "url(/assets/default.jpg)",
            }}
          >
            <h1 className="post-title">{frontmatter.title}</h1>
            <div className="post-meta">{frontmatter.description}</div>
          </div>

          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        description
      }
    }
  }
`;
